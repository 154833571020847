import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import { Container } from "../../../components/Container";
import { InputField } from "../../../components/Form/Input/InputField";

const categorySchema = z.object({
  name: z
    .string()
    .min(1, "O nome deve ser preenchido")
    .max(50, "O nome pode ter no máximo 50 caracteres"),
});

type ICategory = z.infer<typeof categorySchema>;

export const AddCategory = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICategory>({
    resolver: zodResolver(categorySchema),
  });

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data: ICategory) => {
    console.log("Categoria enviada:", data);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Cadastro de Categoria
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <InputField
                  name="name"
                  label="Nome"
                  placeholder="Digite o nome da categoria"
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/categories">
            <button
              type="button"
              onClick={() => handleCancel()}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvar
          </button>
        </div>
      </form>
    </Container>
  );
};
