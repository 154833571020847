import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import paperAirplane from "../../../images/paperAirplane.png";
import menSendMessage from "../../../images/menSendMessage.png";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { addCompanyValidation } from "../../../utils/zodValidations/companyValidation";

type RegisterFormValues = z.infer<typeof addCompanyValidation>;

export function Register() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    resolver: zodResolver(addCompanyValidation),
  });

  const onSubmit = (data: RegisterFormValues) => {
    console.log(data);
  };

  return (
    <>
      <div className="flex flex-col-reverse lg:flex-row items-center max-h-screen px-6 py-12 lg:px-8">
        <div className="flex flex-1 justify-center lg:justify-center">
          <img
            src={menSendMessage}
            alt="Paper Airplane"
            className="max-w-xs lg:max-w-md"
          />
        </div>

        <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <img src={paperAirplane} alt="Logo" className="h-10 w-auto mx-auto" />

          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-black">
              Cadastre sua Empresa
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-black">
                  Nome
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...register("name")}
                    className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                    placeholder="Digite o nome da Empresa"
                  />
                  {errors.name && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.name.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-black">
                  CNPJ
                </label>
                <div className="mt-2">
                  <InputMask
                    mask="99.999.999/9999-99"
                    {...register("cnpj")}
                    onChange={(e) => setValue("cnpj", e.target.value)}
                    className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                    placeholder="Digite o CNPJ"
                  />
                  {errors.cnpj && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.cnpj.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-black">
                  Telefone
                </label>
                <div className="mt-2">
                  <InputMask
                    mask="(99) 99999-9999"
                    {...register("phone")}
                    onChange={(e) => setValue("phone", e.target.value)}
                    className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                    placeholder="Digite o telefone"
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-black">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    {...register("email")}
                    className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                    placeholder="Digite seu email"
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-black">
                  Senha
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    {...register("password")}
                    className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                    placeholder="Digite sua senha"
                  />
                  {errors.password && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-[#205844] px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#205510] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Registrar
                </button>
              </div>
            </form>

            <div className="mt-6">
              <Link to="/">
                <button className="flex w-full justify-center rounded-md border border-gray-300 px-3 py-1.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                  Voltar para Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
