import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { Sidebar } from "../components/Sidebar";
import { Header } from "../components/Header";
import { Company } from "../pages/Company";
import { AddCompany } from "../pages/Company/AddCompany";
import { Services } from "../pages/Service";
import { AddService } from "../pages/Service/AddService";
import { FlowEditor } from "../pages/FlowEditor";
import { ListCategories } from "../pages/Categories/ListCategories";
import { ListEmployees } from "../pages/Employees/ListEmployees";
import { AddCategory } from "../pages/Categories/AddCategory";
import { PrivateRoute } from "./PrivateRoute";
import { SignIn } from "../pages/Auth/SignIn";
import { AddEmployees } from "../pages/Employees/AddEmployees";
import { Register } from "../pages/Auth/Register";

export const RoutesApp = () => {
  return (
    <Routes>
      {/* Rota Pública */}
      <Route path="/" element={<SignIn />} />
      <Route path="/register" element={<Register />} />

      {/* Rotas Privadas */}
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/employees" element={<Layout><ListEmployees /></Layout>} />
        <Route path="/employees/add-employee" element={<Layout><AddEmployees /></Layout>} />
        <Route path="/flow" element={<Layout><FlowEditor /></Layout>} />
        <Route path="/companies" element={<Layout><Company /></Layout>} />
        <Route path="/companies/add-company" element={<Layout><AddCompany /></Layout>} />
        <Route path="/services" element={<Layout><Services /></Layout>} />
        <Route path="/services/add-service" element={<Layout><AddService /></Layout>} />
        <Route path="/categories" element={<Layout><ListCategories /></Layout>} />
        <Route path="/categories/add-category" element={<Layout><AddCategory /></Layout>} />
      </Route>
    </Routes>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => (
  <div>
    <Sidebar />
    <div className="xl:pl-72">
      <Header />
      {children}
    </div>
  </div>
);
