import React from "react";

interface FormRowProps {
  label: string;
  placeholder: string;
  inputId: string;
  onSave: (value: string) => void;
  onCancel: () => void;
}

export const FormRow: React.FC<FormRowProps> = ({
  label,
  placeholder,
  inputId,
  onSave,
  onCancel,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleSave = () => {
    onSave(inputValue);
  };

  return (
    <div className="mt-10 border-b border-gray-900/10 pb-12 sm:border-t sm:pb-0">
      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
        {/* Label */}
        <label
          htmlFor={inputId}
          className="block text-sm font-medium text-gray-900"
        >
          <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20">
            {label}
          </span>
        </label>

        {/* Input and Buttons */}
        <div className="sm:col-span-2 flex items-center gap-4">
          <div className="flex-1 rounded-md bg-white outline outline-1 outline-gray-300 focus-within:outline-2 focus-within:outline-indigo-600">
            <input
              type="text"
              id={inputId}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="block w-full border-0 bg-transparent px-3 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder={placeholder}
            />
          </div>

          {/* Save Button */}
          <button
            type="button"
            onClick={handleSave}
            className="rounded-full bg-green-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
            Salvar
          </button>

          {/* Cancel Button */}
          <button
            type="button"
            onClick={onCancel}
            className="rounded-full bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};
