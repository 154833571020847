import { useForm, Controller } from "react-hook-form";
import { Container } from "../../../components/Container";
import { Link } from "react-router-dom";
import {
  INewService,
  IService,
  servicesData,
} from "../../../interfaces/services.interface";
import { useQueryClient } from "@tanstack/react-query";
import { IEmployee } from "../../../interfaces/employees.interface";

export const AddEmployees = () => {
  const { control, handleSubmit, reset } = useForm<IEmployee>();

  const handleCancel = () => {
    reset();
  };

  const queryClient = useQueryClient();

  const onSubmit = (data: IEmployee) => {
    // postService(data)
    //   .then(() => {
    //     window.alert("Sucesso ao criar serviço!");
    //     queryClient.invalidateQueries({
    //       queryKey: ["services"],
    //     });
    //   })
    //   .catch(() => {
    //     window.alert("Erro ao criar serviço!");
    //   })
    //   .finally(() => {
    //     handleCancel();
    //   });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Cadastro de Funcionário
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nome
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          name="name"
                          id="name"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="XXXXXXXX"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="averageExecutionTime"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="email"
                          name="email"
                          id="email"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="nome@email.com"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="averageExecutionTime"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Telefone
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          name="phone"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="(XX) XXXXX-XXXX"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="executionTimeOptions"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Serviços
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="role"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select
                          {...field}
                          id="category_id"
                          name="category_id"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >
                          {servicesData.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.category_name} - {option.name}
                            </option>
                          ))}
                          <option value="" disabled>
                            Selecione os serviços que esse funcionário executa
                          </option>
                        </select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/employees">
            <button
              type="button"
              onClick={() => handleCancel()}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvar
          </button>
        </div>
      </form>
    </Container>
  );
};
