import { z } from "zod";

export const addCompanyValidation = z.object({
  name: z.string().nonempty("Nome é obrigatório"),
  cnpj: z
    .string()
    .regex(/^\d{14}$/, "CNPJ deve ter 14 dígitos")
    .min(1, "CNPJ é obrigatório"),
  email: z.string().email("Email inválido").min(1, "Email é obrigatório"),
  password: z.string().min(3, "A senha deve ter no mínimo 3 caracteres"),
  phone: z
    .string()
    .regex(/^\d{10,11}$/, "Telefone deve ter 10 ou 11 dígitos")
    .min(1, "Telefone é obrigatório"),
});
