import { AxiosResponse } from "axios";
import { IService } from "../interfaces/services.interface";
import api from "./api";

const baseURL = "services";

export const postService = (data: IService) => {
  const response = api.post(`${baseURL}`, data);
  return response;
};

export const putService = (data: IService) => {
  const response = api.put(`${baseURL}/${data.id}`, data);
  return response;
};

export const deleteService = (id: number) => {
  const response = api.delete(`${baseURL}/${id}`);
  return response;
};

export const getServices = (): Promise<IService[]> =>
  api
    .get("/services")
    .then((response: AxiosResponse<IService[]>) => response.data);

export const getServiceId = (id: number): Promise<IService[]> =>
  api
    .get(`/services/${id}`)
    .then((response: AxiosResponse<IService[]>) => response.data);
