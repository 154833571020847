import React from "react";
import { Controller } from "react-hook-form";

export const formatPhoneNumber = (value: string): string => {
  const cleaned = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  const match = cleaned.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);

  if (!match) return value;
  const [, countryCode, part1, part2] = match;
  if (part2) {
    return `(${countryCode}) ${part1}-${part2}`;
  }
  if (part1) {
    return `(${countryCode}) ${part1}`;
  }
  return `(${countryCode}`;
};
