import { AxiosError, AxiosResponse } from "axios";
import api from "./api";
import { ICompany } from "../interfaces/company.interface";

const baseURL = "companies";

export const postCompany = async (data: ICompany): Promise<any> => {
  try {
    const response = await api.post(`${baseURL}`, data);
    return response.data; // Retorna apenas os dados, se necessário
  } catch (error: any) {
    // Tratamento de erros
    console.error(
      "Error posting company:",
      error.response?.data || error.message
    );
    throw error.response?.data || error; // Propaga o erro para o chamador
  }
};

export const getAllCompanies = (): Promise<ICompany[]> =>
  api
    .get(`${baseURL}`)
    .then((response: AxiosResponse<ICompany[]>) => response.data);

export const getCompanyId = (id: number): Promise<ICompany[]> =>
  api
    .get(`/${baseURL}/${id}`)
    .then((response: AxiosResponse<ICompany[]>) => response.data);

export const putCompany = (data: ICompany) => {
  const response = api.put(`${baseURL}/${data.cnpj}`, data);
  return response;
};
