import { INewService, IService } from "./services.interface";

export interface IEmployee {
  id: string;
  name: string;
  email: string;
  phone: number;
  company_id: string;
  role: string;
  created_at: string;
  active: boolean;
  services: Array<INewService>;
}

export const employeesData: IEmployee[] = [
  {
    id: "1",
    name: "João Silva",
    email: "joao.silva@example.com",
    phone: 5521999999999,
    company_id: "123",
    role: "Manager",
    created_at: "2023-12-01T10:00:00Z",
    active: true,
    services: [
      {
        id: "1",
        name: "Limpeza Residencial",
        price: 120.0,
        averageExecutionTime: 90,
        company_id: "123",
        category_id: "1",
        category_name: "Limpeza",
        created_at: "2023-12-01T10:00:00Z",
      },
    ],
  },
  {
    id: "2",
    name: "Maria Oliveira",
    email: "maria.oliveira@example.com",
    phone: 5521988888888,
    company_id: "123",
    role: "Employee",
    created_at: "2023-12-02T11:00:00Z",
    active: true,
    services: [
      {
        id: "2",
        name: "Manutenção Elétrica",
        price: 200.0,
        averageExecutionTime: 120,
        company_id: "123",
        category_id: "2",
        category_name: "Manutenção",
        created_at: "2023-12-02T11:00:00Z",
      },
    ],
  },
  {
    id: "3",
    name: "Carlos Mendes",
    email: "carlos.mendes@example.com",
    phone: 5521977777777,
    company_id: "124",
    role: "Supervisor",
    created_at: "2023-12-03T12:00:00Z",
    active: false,
    services: [],
  },
];
