import React from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Container } from "../../../components/Container";
import { Link } from "react-router-dom";
import {
  INewService,
  servicesData,
} from "../../../interfaces/services.interface";
import { useQueryClient } from "@tanstack/react-query";
import { categoriesData } from "../../../interfaces/category.interface";

// Esquema de validação Zod
const newServiceSchema = z.object({
  name: z
    .string()
    .min(3, "O nome do serviço deve ter no mínimo 3 caracteres")
    .max(50, "O nome do serviço deve ter no máximo 50 caracteres"),
  averageExecutionTime: z
    .number()
    .min(1, "O tempo de execução deve ser no mínimo 1")
    .max(100, "O tempo de execução deve ser no máximo 100"),
  category_id: z.string().nonempty("A categoria do serviço é obrigatória"),
});

// Tipagem gerada pelo Zod
type NewServiceForm = z.infer<typeof newServiceSchema>;

export const AddService = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewServiceForm>({
    resolver: zodResolver(newServiceSchema), // Integrando o Zod ao react-hook-form
    defaultValues: {
      name: "",
      averageExecutionTime: 0,
      category_id: "",
    },
  });

  const handleCancel = () => {
    reset();
  };

  const queryClient = useQueryClient();

  const onSubmit = (data: NewServiceForm) => {
    console.log(data);
    // postService(data)
    //   .then(() => {
    //     window.alert("Sucesso ao criar serviço!");
    //     queryClient.invalidateQueries({
    //       queryKey: ["services"],
    //     });
    //   })
    //   .catch(() => {
    //     window.alert("Erro ao criar serviço!");
    //   })
    //   .finally(() => {
    //     handleCancel();
    //   });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Cadastro de Serviço
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* Campo Nome Serviço */}
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nome Serviço
                </label>
                <div className="mt-2">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="name"
                        className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Nome do Serviço"
                      />
                    )}
                  />
                  {errors.name && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.name.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Campo Tempo de Execução */}
              <div className="sm:col-span-4">
                <label
                  htmlFor="averageExecutionTime"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tempo de Execução
                </label>
                <div className="mt-2">
                  <Controller
                    name="averageExecutionTime"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        id="averageExecutionTime"
                        className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Tempo de Execução"
                      />
                    )}
                  />
                  {errors.averageExecutionTime && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.averageExecutionTime.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Campo Categoria */}
              <div className="sm:col-span-4">
                <label
                  htmlFor="category_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Categoria Serviço
                </label>
                <div className="mt-2">
                  <Controller
                    name="category_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        id="category_id"
                        className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled>
                          Selecione uma categoria
                        </option>
                        {categoriesData.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.category_id && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.category_id.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/services">
            <button
              type="button"
              onClick={() => handleCancel()}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvar
          </button>
        </div>
      </form>
    </Container>
  );
};
