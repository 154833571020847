export interface IService {
  id?: number;
  name: string;
  averageExecutionTime: number;
}

export interface INewService {
  id: string;
  name: string;
  price: number;
  averageExecutionTime: number;
  company_id: string; 
  category_id: string;
  category_name: string;
  created_at: string;
}

export const servicesData: INewService[] = [
  {
    id: "1",
    name: "Limpeza Residencial",
    price: 120.0,
    averageExecutionTime: 90,
    company_id: "123",
    category_id: "1",
    category_name: "Limpeza",
    created_at: "2023-12-01T10:00:00Z",
  },
  {
    id: "2",
    name: "Manutenção Elétrica",
    price: 200.0,
    averageExecutionTime: 120,
    company_id: "123",
    category_id: "2",
    category_name: "Manutenção",
    created_at: "2023-12-02T11:00:00Z",
  },
  {
    id: "3",
    name: "Corte de Cabelo",
    price: 80.0,
    averageExecutionTime: 60,
    company_id: "124",
    category_id: "3",
    category_name: "Beleza",
    created_at: "2023-12-03T12:00:00Z",
  },
  {
    id: "4",
    name: "Pintura de Paredes",
    price: 300.0,
    averageExecutionTime: 180,
    company_id: "125",
    category_id: "4",
    category_name: "Pintura",
    created_at: "2023-12-04T13:00:00Z",
  },
  {
    id: "5",
    name: "Instalação de Ar-Condicionado",
    price: 400.0,
    averageExecutionTime: 240,
    company_id: "126",
    category_id: "5",
    category_name: "Instalação",
    created_at: "2023-12-05T14:00:00Z",
  },
];
