import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BookmarkIcon,
  BuildingStorefrontIcon,
  ChatBubbleLeftIcon,
  ChatBubbleLeftRightIcon,
  HomeIcon,
  UserGroupIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Sidebar = () => {
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [navigation, setNavigation] = useState([
    { name: "Home", href: "/home", icon: HomeIcon, current: false },
    {
      name: "Empresas",
      href: "/companies",
      icon: BuildingStorefrontIcon,
      current: false,
      roles: ["adm@gmail.com"], // Apenas administradores
    },
    {
      name: "Funcionários",
      href: "/employees",
      icon: UserGroupIcon,
      current: false,
      roles: ["empresa@gmail.com"], // Vários perfis podem acessar
    },
    {
      name: "Serviços",
      href: "/services",
      icon: WrenchIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
    {
      name: "Categorias",
      href: "/categories",
      icon: BookmarkIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
    {
      name: "Flow WhatsApp",
      href: "/flow",
      icon: ChatBubbleLeftIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
  ]);

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem("user") || "{}");

    // Filtra as telas com base no perfil
    const filteredNavigation = navigation.filter(
      (item) => !item.roles || item.roles.includes(userProfile)
    );

    console.log("Filtered Navigation:", filteredNavigation);

    // Atualiza a navegação com o estado da rota atual
    setNavigation(
      filteredNavigation.map((item) => ({
        ...item,
        current: location.pathname === item.href, // Marca a rota atual
      }))
    );
  }, [location]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 xl:hidden"
          onClose={setSidebarOpen}
        >
          {/* Sidebar mobile */}
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <ChatBubbleLeftRightIcon className="h-8 w-auto text-white" />
                    <span className="text-white ml-2">CHAT</span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Sidebar desktop */}
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col bg-gray-900">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
          <div className="flex h-16 shrink-0 items-center">
            <ChatBubbleLeftRightIcon className="h-8 w-auto text-white" />
            <span className="text-white ml-2">CHAT</span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
