import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import menSendMessage from "../../../images/womanOnTablet.png";
import paperAirplane from "../../../images/paperAirplane.png";
import toast from "react-hot-toast";

const signInSchema = z.object({
  email: z.string().email("Email inválido").nonempty("Email é obrigatório"),
  password: z.string().min(3, "A senha deve ter no mínimo 3 caracteres"),
});

type SignInFormValues = z.infer<typeof signInSchema>;

export function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    resolver: zodResolver(signInSchema),
  });

  const onSubmit = (data: SignInFormValues) => {
    if (
      data.email === "adm@gmail.com" ||
      (data.email === "empresa@gmail.com" && data.password === "123")
    ) {
      const token = Math.random().toString(36).substring(2);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(data.email));

      toast.success("Login realizado com sucesso!");
      window.location.href = "/home";
    } else {
      toast.error("Não foi possível realizar o login.!");
    }
  };

  return (
    <div className="flex flex-col-reverse lg:flex-row items-center min-h-screen px-6 py-12 lg:px-8">
      <div className="flex flex-1 justify-center lg:justify-center">
        <img
          src={menSendMessage}
          alt="Men Sending Message"
          className="max-w-xs lg:max-w-md"
        />
      </div>

      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <img src={paperAirplane} alt="Logo" className="h-10 w-auto mx-auto" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-black">
            Bem-vindo
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-black"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  type="email"
                  {...register("email")}
                  className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                  placeholder="Digite seu email"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.email.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-black"
                >
                  Senha
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-yellow-400 hover:text-yellow-300"
                  >
                    Esqueceu a senha?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  type="password"
                  {...register("password")}
                  className="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-black outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm"
                  placeholder="Digite sua senha"
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#205844] px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#205510] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Entrar
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-400">
            Não tem uma conta?{" "}
            <a
              href="/register"
              className="font-semibold text-[#205844] hover:text-[#205510]"
            >
              Cadastre-se
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
