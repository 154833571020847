import { RoutesApp } from "./routes";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <BrowserRouter basename="">
      <RoutesApp />
      <Toaster/>
    </BrowserRouter>
  );
}
