import React from "react";
import { Container } from "../../components/Container";
import { useForm } from "react-hook-form";
import { FormRow } from "../../components/FormRow";
import { ICompany } from "../../interfaces/company.interface";

export const FlowEditor = () => {
  const { reset } = useForm<ICompany>();

  const handleCancel = () => {
    reset();
  };

  const handleSave = (step: string, value: string) => {
    console.log(`Step: ${step}, Value: ${value}`);
    alert(`Message for "${step}" saved successfully!`);
  };

  return (
    <Container>
      <form>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold text-gray-900">
              Flow WhatsApp
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-600">
              Personalize o fluxo de mensagens
            </p>

            {/* Saudação Inicial */}
            <FormRow
              label={"Saudação Inicial:"}
              placeholder={"Olá! Bem-vindo à [Nome da Empresa]. Como podemos ajudar?"}
              inputId={"greeting"}
              onSave={(value) => handleSave("Saudação Inicial", value)}
              onCancel={handleCancel}
            />

            {/* Seleção de Categoria */}
            <FormRow
              label={"Seleção de Categoria:"}
              placeholder={"Por favor, escolha uma categoria"}
              inputId={"category-selection"}
              onSave={(value) => handleSave("Seleção de Categoria", value)}
              onCancel={handleCancel}
            />

            {/* Seleção de Serviço */}
            <FormRow
              label={"Seleção de Serviço:"}
              placeholder={"Por favor, escolha um serviço"}
              inputId={"service-selection"}
              onSave={(value) => handleSave("Seleção de Serviço", value)}
              onCancel={handleCancel}
            />

            {/* Seleção de Data */}
            <FormRow
              label={"Seleção de Data:"}
              placeholder={"Por favor, escolha uma data"}
              inputId={"date-selection"}
              onSave={(value) => handleSave("Seleção de Data", value)}
              onCancel={handleCancel}
            />

            {/* Seleção de Horário */}
            <FormRow
              label={"Seleção de Horário:"}
              placeholder={"Por favor, escolha um horário"}
              inputId={"time-selection"}
              onSave={(value) => handleSave("Seleção de Horário", value)}
              onCancel={handleCancel}
            />

            {/* Confirmação de Agendamento */}
            <FormRow
              label={"Confirmação de Agendamento:"}
              placeholder={"Seu agendamento foi confirmado para [Data] às [Hora]."}
              inputId={"appointment-confirmation"}
              onSave={(value) => handleSave("Confirmação de Agendamento", value)}
              onCancel={handleCancel}
            />

            {/* Mensagem de Feedback */}
            <FormRow
              label={"Mensagem de Feedback:"}
              placeholder={"Como foi o nosso serviço? Avalie de 1 a 5."}
              inputId={"feedback-message"}
              onSave={(value) => handleSave("Mensagem de Feedback", value)}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};
