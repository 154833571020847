import React, { useState } from "react";
import { Container } from "../../../components/Container";
import { Link } from "react-router-dom";
import {
  categoriesData,
  ICategory,
} from "../../../interfaces/category.interface";
import { Paginator } from "../../../components/Table/Paginator";

export const ListCategories = () => {
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const itemsPerPage = 3; // Itens por página

  // Calcula os itens exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categoriesData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Container>
      <>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Lista de Categorias
            </h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Link to="/categories/add-category">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Adicionar Categoria
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Nome Categoria
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentItems.map((item: ICategory) => (
                    <tr key={item.id}>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{item.name}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Paginator */}
              <Paginator
                totalItems={categoriesData.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </>
    </Container>
  );
};
