import React from "react";
import { Controller } from "react-hook-form";

interface InputFieldProps {
  name: string;
  label: string;
  placeholder: string;
  control: any; // Tipado como "any" porque o Controller exige o tipo do form context
  errors?: any; // Para acessar erros do `react-hook-form`
  type?: string; // Tipo do input (default: "text")
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  placeholder,
  control,
  errors,
  type = "text",
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                {...field}
                type={type}
                id={name}
                className="block flex-1 border-0 bg-transparent px-3 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder={placeholder}
              />
            )}
          />
        </div>
        {/* Exibição do erro */}
        {errors && errors[name] && (
          <p className="mt-2 text-sm text-red-600">{errors[name].message}</p>
        )}
      </div>
    </div>
  );
};
